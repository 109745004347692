import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Button } from "../components/Button"
import SEO from "../components/seo"
import { below } from "../styles"
import rachiosaurImg from "../images/pages/404/404-graphic-footer-1920px.png"
import rawrImg from "../images/pages/404/404-dinosoar-rawr.png"

const NotFound = styled.div`
  background: var(--navyBlue);
  text-align: center;
  transition: all 0.5s ease-out;
  padding-top: var(--topSectionPadding);
  opacity: 0;
  .rachiosaur-wrapper {
    position: relative;
    max-width: 100%;
    margin: 10rem auto 0;
    .rachiosaur {
      width: 100%;
      height: auto;
    }
    &:hover #rawr {
      display: block;
    }
    #rawr {
      position: absolute;
      left: 58%;
      top: -15rem;
      display: none;
    }
  }
  ${below.medium`
    .rachiosaur-wrapper {
      #rawr {
        display: block;
        width: 15rem;
        top: -8rem;
        height: auto
      }
    }
  `}
`

const NotFoundPage = () => {
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setShowLoading(true), 100)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <>
      <SEO title="404" pathname="/404/" />
      <NotFound style={showLoading ? { opacity: 1 } : {}}>
        <h1 css="margin-top: 0; color: var(--primaryColor); font-size: 12rem">
          404
        </h1>
        <p className="large" css="color: var(--white)">
          Uh oh. Something&apos;s gone quite wrong...
        </p>
        <Button as={Link} to="/" color="white">
          Get me out of here
        </Button>
        <div className="rachiosaur-wrapper">
          <img src={rachiosaurImg} className="rachiosaur" alt="rachiosaur" />
          <img src={rawrImg} id="rawr" alt="rawr" />
        </div>
      </NotFound>
    </>
  )
}

export default NotFoundPage
